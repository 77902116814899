import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import Modal from 'react-modal';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import './i18next/i18next';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_API_BASE_URL,
  REACT_APP_SENTRY_DSN,
  REACT_APP_DEPLOY_ENV,
  REACT_APP_RELEASE,
} = process.env;

const tracesSampleRate = 0.25;

Modal.setAppElement('#root');

const persistor = persistStore(store);

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_DEPLOY_ENV,
  release: REACT_APP_RELEASE,
  integrations: [Sentry.browserTracingIntegration()],
  // Warning: Don't set too deep or errors won't
  // get send as they are getting to big!
  normalizeDepth: 3,
  tracesSampleRate,
  autoSessionTracking: true,
  ignoreErrors: ['ResizeObserver loop'],
  tracePropagationTargets: [REACT_APP_API_BASE_URL],
  debug: false,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN}
        clientId={REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience="https://api.gc-roadbook.de/"
        cacheLocation="localstorage"
      >
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
